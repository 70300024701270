:root {
  --lms-l-orange: #f99b1c;
  --lms-m-orange: #f3702b;
  --lms-c-black: #4c5d6d;
  --lms-s-grey: #678099;
  --lms-d-orange: #ef432d;
  --lms-l-blue: #98b5d5;
  --lms-gold: #eec559;
}

@font-face {
  font-family: 'proxima-nova';
  src: url('./assets/fonts/proximanova/proximanova-regular\ 2.otf') format('opentype');
}

@font-face {
  font-family: 'proxima-nova-italic';
  src: url('./assets/fonts/proximanova/proximanova-regularit.otf') format('opentype');
}

@font-face {
  font-family: 'proxima-nova-bold';
  src: url('./assets/fonts/proximanova/proximanova-bold\ 2.otf') format('opentype');
}

@font-face {
  font-family: 'proxima-nova-bold-italic';
  src: url('./assets/fonts/proximanova/proximanova-boldit\ 2.otf') format('opentype');
}

@font-face {
  font-family: 'proxima-nova-light';
  src: url('./assets/fonts//proximanova/proximanova-light\ 2.otf') format('opentype');
}

@font-face {
  font-family: 'proxima-nova-light-italic';
  src: url('./assets/fonts/proximanova/proximanova-lightit.otf') format('opentype');
}

@font-face {
  font-family: 'gotham-htf-medium';
  src: url('./assets/fonts/gotham/GothamHTF-Medium.otf') format('opentype');
}

@font-face {
  font-family: 'gotham-htf-bold';
  src: url('./assets/fonts/gotham/GothamHTF-Bold.otf') format('opentype');
}

@font-face {
  font-family: 'gotham-htf-book';
  src: url('./assets/fonts/gotham/GothamHTF-Book.otf') format('opentype');
}

@font-face {
  font-family: 'gotham-htf-xlight';
  src: url('./assets/fonts/gotham/GothamHTF-XLight.otf') format('opentype');
}


@font-face {
  font-family: 'archer-book';
  src: url('./assets/fonts/archer/Archer-Book.otf') format('opentype');
}

@font-face {
  font-family: 'archer-book-italic';
  src: url('./assets/fonts/archer/Archer-BookItal.otf') format('opentype');
}

@font-face {
  font-family: 'archer-semibold';
  src: url('./assets/fonts/archer/Archer-Semibold.otf') format('opentype');
}

@font-face {
  font-family: 'archer-semibold-italic';
  src: url('./assets/fonts/archer/Archer-SembdItal.otf') format('opentype');
}

@font-face {
  font-family: 'archer-bold';
  src: url('./assets/fonts/archer/Archer-Bold.otf') format('opentype');
}

@font-face {
  font-family: 'archer-bold-italic';
  src: url('./assets/fonts/archer/Archer-BoldItal.otf') format('opentype');
}

.App {
  text-align: center;
}

html {
  height:100%;
  box-sizing: border-box;

}

body {
  font-size: 17px;
  height:100%;

}


.header-top-panel {
  box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  z-index: 100;
  width: 100%;
  border: 1px solid #e5e5e5;
  background-color: #fcfdff;
}

.site-switcher {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.ribbon-logo {
  width: 131px;
  height: 22px;
  padding: 9px;
  border-right: 1px solid #e5e5e5;
  vertical-align:top
}


.inactive {
  opacity: .5;
  height: 18px;
}

.socials {
  font-size:16px;
  padding: 0px 20px 0px 0px;
  display: flex;
  align-items: center;
}

.socials a {
  display: flex;
  align-items:inherit;
  margin: 0 8px;
  color: #ccc;
}

.socials .fa-linkedin-in {
  font-size: 21px;
  padding-top:3px;
}

.socials .fa-facebook-f {
  font-size: 17px;
  padding-top: 1px;
}

.socials .fa-twitter {
  font-size: 19px;
  padding-top: 1px;
  padding-left: 1px;

}

.socials .fa-instagram {
  font-size: 20px;
}

.socials .fa-youtube {
  font-size: 17px;
}

.svg-inline--fa.fa-w-14, .svg-inline--fa.fa-w-16 {
  width: 1.1em;
  height: 1em
}

.svg-inline--fa.fa-w-10 {
  width: .600em;
}

.footer-container {
  max-width: 1054px;
  margin: 0 auto;
  padding: 0 15px;
}

.content-wrapper {
  min-height: calc(100vh - 300px);
  display: flex;
  flex-direction: column;
}

.pageContent{
  flex:1 0 auto;
}


.main-footer{
  height:1%;
  text-align: center;
  letter-spacing: .03em;
  background: #f3f6fa;
  color:var(--lms-s-grey);
  font-size: 13px;
  font-family: proxima-nova;
  line-height:1.4;
  flex: none
}

.main-footer a{
  color: var(--lms-s-grey);
}

.main-footer .footer-container {
  padding: 45px 15px 60px 15px
}

.footer-logo {
  display: block;
  width: 152px;
  margin: 0 auto 25px;
}

.social-networks{
  margin-bottom: 8.5px;
  padding-top: 3.5px;

}
.social-networks a {
  margin: 0 6px 12px;
  padding: 1.5px;
  line-height:0;
  /* display: block; */
  box-sizing: inherit;
  color: var(--lms-s-grey);
  
}

.social-networks .fa-facebook-f {
  font-size:17.5px;
  padding-bottom: 2px;

}


.social-networks .fa-twitter {
  font-size:17.5px;
  padding-bottom: 1px;
}

.social-networks .fa-instagram{
  font-size:20px;

}

.social-networks .fa-youtube{
  font-size:19.5px;
}

.social-networks .fa-linkedin-in {
  font-size:18px;
  padding-top: 1px;
}

.privacy-footer{
  font-size: 12px;
  margin-bottom: 0
}


@media (max-width: 1023px){
  .main-footer .footer-container {
    padding: 20px 15px 25px 15px
  }
  .footer-logo {
    width: 140px;
  }
  .content-wrapper {
    min-height: calc(100vh - 254px);
  }
}
/* media queries begin */
@media (max-width: 767px){
  .ribbon-logo {
      width: 62px;
      height: 24px;
      padding: 6px;
  }
  .socials {
      padding: 0 5px;
  }

  .footer-logo{
    width: 120px;
  }

  .content-wrapper {
    min-height: calc(100vh - 242px);
  }
}


